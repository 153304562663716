import {acceptHMRUpdate, defineStore} from "pinia";
import qs from 'qs';

export const useServices = defineStore("services", {
        state: () => {
            const config = useRuntimeConfig();

            return {
                apiBase: config.public.apiBase,
                cache: config.public.cache,
                debug: config.STAGE === 'local',
                listServices: [],
                listCategories: [],
                listCategoriesMenu: [],
                listChapters: [],
                textSearch: "",
            };
        },

        getters: {
            getServiceById: (state) => (serviceId) =>
                state.listServices.find((item) => item.id === serviceId),
            getServiceByLink: (state) => (link) =>
                state.listServices.find((item) => item.attributes.link === link),
            filterListChapters: (state) => {
                return state.listChapters
                    .map((chapter) => {
                        let result = JSON.parse(JSON.stringify(chapter));
                        if (state.textSearch.length < 3) return result;

                        result.attributes.services.data =
                            chapter.attributes.services.data.filter((service) => {
                                if (
                                    service.attributes.title
                                        .toLowerCase()
                                        .indexOf(state.textSearch.toLowerCase().trim()) !== -1
                                ) {
                                    return true;
                                }
                            });
                        return result;
                    })
                    .filter((chapter) => chapter.attributes.services.data.length);
            },
            createApiUrl: (state) => (url) => {
                return `${state.apiBase}${url}`;
                // return `${state.apiBase}${url}${
                //     state.cache ? "" : "&" + new Date().getTime()
                // }`;
            },
        },

        actions: {
            async fetchServices() {
                const startTime = performance.now();
                const url = "/api/services?pagination[pageSize]=1000&sort=title&populate[specials]=*&populate[clinics]=*";
                const listServices = await fetch(
                    this.createApiUrl(url)
                )
                    .then((response) => response.json())
                    .then((result) => result.data)
                    .catch((error) => console.log("error", error));
                if (listServices?.length) {
                    this.listServices = [...listServices];
                } else {
                    console.log("List services is empty");
                }
                const endTime = performance.now();
                const durationInSeconds = (endTime - startTime) / 1000;
                if (this.debug)
                    console.log(`${durationInSeconds} - ${url}`);
            },
            async fetchService(serviceId) {
                const startTime = performance.now();
                const url = `/api/services/${serviceId}?populate=*`;
                const service = await fetch(
                    this.createApiUrl(url)
                )
                    .then((response) => response.json())
                    .then((result) => result.data)
                    .catch((error) => console.log("error", error));
                if (Object.values(service).length) {
                    return service;
                } else {
                    console.log("Service is empty");
                }
                const endTime = performance.now();
                const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
                if (this.debug)
                    console.log(`${durationInSeconds} - ${url}`);
            },
            async fetchServiceByLink(link, extended) {
                const filters = {
                    link: {
                        $eq: link,
                    },
                };
                let populate = '*';
                if (extended) {
                    populate = {
                        serviceinfo: {
                            populate: {
                                Doc: {
                                    populate: '*',
                                },
                                Price: {
                                    populate: '*',
                                },
                                image: {
                                    populate: '*',
                                },
                                faq: {
                                    populate: '*',
                                },
                            },
                        },
                    };
                }
                const query = qs.stringify({filters, populate}, {encode: true});
                const url = `/api/services?${query}`;

                const startTime = performance.now();
                const services = await fetch(
                    `${this.apiBase}${url}`
                )
                    .then((response) => response.json())
                    .then((result) => result.data)
                    .catch((error) => console.log("error", error));
                const endTime = performance.now();
                const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
                if (this.debug)
                    console.log(`${durationInSeconds} - ${url}`);

                if (Object.values(services).length) {
                    return services[0];
                } else {
                    console.log("Service is empty");
                }
            },
            async fetchCategoryMenu() {
                try {

                    const url = "/api/categories?populate[menu][populate][service]=*"
                    const startTime = performance.now();
                    const response = await fetch(this.createApiUrl(url));
                    const result = await response.json();
                    const endTime = performance.now();
                    const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
                    if (this.debug)
                        console.log(`${durationInSeconds} - ${url}`);

                    const listCategoriesMenu = result.data;

                    if (listCategoriesMenu?.length) {
                        this.listCategoriesMenu = [...listCategoriesMenu];
                    } else {
                        console.log("List categories is empty");
                    }


                } catch (error) {
                    console.log("error", error);
                }
            },
            async fetchCategory() {
                const listCategories = await fetch(
                    this.createApiUrl("/api/categories")
                )
                    .then((response) => response.json())
                    .then((result) => result.data)
                    .catch((error) => console.log("error", error));
                if (listCategories?.length) {
                    this.listCategories = [...listCategories];
                } else {
                    console.log("List categories is empty");
                }
            },
            async fetchChapters() {
                const startTime = performance.now();
                const url = "/api/chapters?populate[services]=*";
                const listChapters = await fetch(
                    this.createApiUrl(url)
                )
                    .then((response) => response.json())
                    .then((result) => result.data)
                    .catch((error) => console.log("error", error));
                if (listChapters?.length) {
                    this.listChapters = [...listChapters];
                } else {
                    console.log("List chapters is empty");
                }
                const endTime = performance.now();
                const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
                if (this.debug)
                    console.log(`${durationInSeconds} - ${url}`);
            },
        },
    })
;

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useServices, import.meta.hot));
}
